




























































import GoBackHeader from "@/components/shared/GoBackHeader.vue";
import { get_survey_questions } from "@/store/apis/recruiter";
import {
  ADD_SURVEY_QUESTION,
  GET_SURVEY_QUESTIONS,
  UPDATE_SURVEY_QUESTION
} from "@/store/modules/recruiter/constants";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import { text } from "stream/consumers";
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default Vue.extend({
  components: { GoBackHeader },
  name: "EditSurveyQuestion",
  data() {
    return {
      text: "",
      text_arabic: "",
      type: "",
      items: ["rating", "text-field", "closed-question"],
      questionId: 0
    };
  },
  created() {
    // Get the ID from the route
    this.questionId = Number(this.$route.params.id);
  },
  async mounted() {
    let question = this.get_survey_questions.find(
      (question: any) => question.id === this.questionId
    );
    console.log(question, "===========question");
    this.text = question.text;
    this.text_arabic = question.text_arabic;
    this.type = question.type;
  },
  computed: {
    ...mapGetters("recruiter", {
      get_survey_questions: GET_SURVEY_QUESTIONS
    })
  },
  methods: {
    ...mapActions("recruiter", {
      update_survey_question: UPDATE_SURVEY_QUESTION
    }),
    ...mapMutations({
      set_root_error: ROOT_ERROR,
      set_root_notification: ROOT_NOTIFICATION
    }),

    // Function to update company assessment setting
    async saveChanges() {
      // API Call
      const payload = {
        text: this.text,
        text_arabic: this.text_arabic,
        type: this.type
      };
      const response = await this.update_survey_question({
        payload,
        id: this.questionId
      });
      if (response) {
        this.set_root_notification(this.$t("survey_questions.survey-success"));
        this.$router.push("/recruiter/survey-questions");
      }
    }
  }
});
